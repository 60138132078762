/**
 * Combined by jsDelivr.
 * Original files:
 * - /gh/watchcharts/cdn@master/js/libs/jquery.min.js
 * - /gh/watchcharts/cdn@master/js/libs/popper.min.js
 * - /gh/watchcharts/cdn@master/js/libs/lazyload.17.1.min.js
 * - /gh/watchcharts/cdn@master/js/libs/featherlight.min.js
 * - /gh/watchcharts/cdn@master/js/libs/featherlight.gallery.min.js
 * - /gh/watchcharts/cdn@master/js/libs/js.cookie.min.js
 * - /gh/watchcharts/cdn@master/js/libs/jquery.dotdotdot.min.js
 * - /gh/watchcharts/cdn@master/js/libs/jquery.appear.1.min.js
 * - /gh/watchcharts/cdn@master/js/libs/iframeResizer.min.js
 * - /gh/watchcharts/cdn@master/js/libs/jquery-ui-slider.min.js
 * - /gh/watchcharts/cdn@master/js/libs/jquery.ui.touch-punch.min.js
 * - /gh/watchcharts/cdn@master/js/libs/dropzone.min.js
 * - /gh/watchcharts/cdn@master/js/libs/moment.min.js
 * - /gh/watchcharts/cdn@master/js/bootstrap/bootstrap.bundle-5.3.2.min.js
 * - /gh/watchcharts/cdn@master/js/bootstrap/bootstrap-select-1.14.0-beta3.min.js
 * - /gh/watchcharts/cdn@master/js/bootstrap/bootstrap-datepicker.min.js
 * - /gh/watchcharts/cdn@master/js/chartjs/chart.min.js
 * - /gh/watchcharts/cdn@master/js/chartjs/chartjs-adapter-date.min.js
 * - /gh/watchcharts/cdn@master/js/chartjs/chartjs-plugin-datalabels.min.js
 * - /gh/watchcharts/cdn@master/js/chartjs/chartjs-plugin-downsample.min.js
 * - /gh/watchcharts/cdn@master/js/chartjs/chartjs-chart-boxplot.min.js
 * - /gh/watchcharts/cdn@master/js/chartjs/chartjs-plugin-annotation2.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
